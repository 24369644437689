<template>
  <div class="px-5 pt-2 flex flex-col shadow m-5">
    <div class="flex justify-between items-center m-1">
      <div class="flex gap-2 mb-2">
        <span class="font-bold text-sm leading-6 tracking-normal uppercase text-romanSilver">Employee:</span>
        <span class="font-bold text-sm leading-6 tracking-normal capitalize text-darkPurple">
          {{ name }}
        </span>
      </div>
      <div class="flex justify-start items-center gap-2 cursor-pointer" v-if="performanceHistory">
        <Icon
          class-name="text-flame"
          size="xs"
          icon-name="icon-trending-up"
        />
        <span class="font-semibold text-base leading-5 text-flame">
          Performance History
        </span>
      </div>
    </div>
    <div class="flex gap-5">
      <div class="flex-1 w-2/12">
        <img :src="avatar"
            v-if="avatar !== '' && avatar !== null"
          style="width: 150px;height: 140px"
        />
          <div
            style="height: 138px; width: 138px; border-radius: 5px"
            class="text-blueCrayola border text-center font-black text-lg pt-14 mr-2"
            v-else
          >
            {{ $getInitials(`${name} `) }}
          </div>

      </div>
      <div class="flex flex-col flex-3 w-10/12">
        <div class="flex mb-2 w-full gap-2">
          <div class="flex flex-1 p-3 shadow rounded-md gap-2 items-start">
            <Icon
              class-name="text-romanSilver"
              size="xs"
              icon-name="calendar"
            />
            <div class="flex flex-col">
              <span class="font-bold text-xs tracking-wide text-carrotOrange uppercase">Designation</span>
              <span class="font-bold text-sm leading-6 tracking-wide text-jet uppercase">
                {{ designation }}
              </span>
            </div>
          </div>
          <div class="flex flex-1 p-3 shadow rounded-md gap-2 items-start">
            <Icon
              class-name="text-romanSilver"
              size="xs"
              icon-name="icon-department"
            />
            <div class="flex flex-col">
              <span class="font-bold text-xs tracking-wide text-blueCrayola uppercase">Function</span>
              <span class="font-bold text-sm leading-6 tracking-wide text-jet uppercase">
                {{ position }}
              </span>
            </div>
          </div>
          <div class="flex flex-1 p-3 shadow rounded-md gap-2 items-start">
            <Icon
              class-name="text-romanSilver"
              size="xs"
              icon-name="user"
            />
            <div class="flex flex-col">
              <span class="font-bold text-xs tracking-wide text-mediumSeaGreen uppercase">Line Manager</span>
              <span class="font-bold text-sm leading-6 tracking-wide text-jet uppercase">
                {{ lineManager }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex mb-4 w-full gap-2">
          <div class="flex flex-1 p-3 shadow rounded-md gap-2 items-start w-1/3">
            <Icon
              class-name="text-romanSilver"
              size="xs"
              icon-name="icon-user-list"
            />
            <div class="flex flex-col">
              <span class="font-bold text-xs tracking-wide text-lite uppercase">Employee ID</span>
              <span class="font-bold text-sm leading-6 tracking-wide text-jet uppercase">
                {{ employeeId }}
              </span>
            </div>
          </div>
          <div class="flex flex-2 p-3 shadow rounded-md gap-2 items-start w-2/3">
            <Icon
              class-name="text-romanSilver"
              size="xs"
              icon-name="pin"
            />
            <div class="flex flex-col">
              <span class="font-bold text-xs tracking-wide text-flame uppercase">Office Location</span>
              <span class="font-bold text-sm leading-6 tracking-wide text-jet uppercase">
                {{ officeLocation }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Icon from "@/components/Icon";

  export default {
    name: "EmployeeDetails",
    components: {
      Icon
    },
    props: {
      name: {
        type: String,
        required: false,
        default: ''
      },
      designation: {
        type: String,
        required: false,
        default: ''
      },
      position: {
        type: String,
        required: false,
        default: ''
      },
      lineManager: {
        type: String,
        required: false,
        default: ''
      },
      employeeId: {
        type: String,
        required: false,
        default: ''
      },
      officeLocation: {
        type: String,
        required: false,
        default: ''
      },
      performanceHistory: {
        type: Boolean,
        required: false,
        default: true
      },
      avatar: {
        type: String,
        required: false,
        default: 'male-user.png'
      },
    },
    data(){
      return {
        showDetails: false,
      }
    },
    methods: {
      handleShowDetails(){
        this.showDetails = !this.showDetails;
      },
    },
  };
  </script>